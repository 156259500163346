export const greeting = {
  dashboardGreeting: "Welcome back👋",
  adminGreeting: "Admins settings",
  justGreeting: "Hi Manoj,",
};

export const tableTitles = [
  "Date",
  "Time Slot",
  "AQI",
  "Humidity",
  "Temperature",
];

export const adminMenuTitle = ["Create Admin", "Admins"];
