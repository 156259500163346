import React from "react";

const SettingsIcon = () => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8067 6.62357L18.1842 5.54348C17.6577 4.62956 16.4907 4.31427 15.5755 4.83867V4.83867C15.1399 5.0953 14.6201 5.16811 14.1307 5.04104C13.6413 4.91398 13.2226 4.59747 12.9668 4.16133C12.8023 3.8841 12.7139 3.56835 12.7105 3.24599V3.24599C12.7254 2.72918 12.5304 2.22836 12.17 1.85762C11.8096 1.48689 11.3145 1.27782 10.7975 1.27803H9.5435C9.03697 1.27802 8.55131 1.47987 8.194 1.8389C7.83669 2.19793 7.63717 2.68455 7.63961 3.19107V3.19107C7.6246 4.23688 6.77248 5.07677 5.72657 5.07666C5.40421 5.07331 5.08846 4.9849 4.81123 4.82036V4.82036C3.89606 4.29597 2.72911 4.61125 2.20254 5.52517L1.53435 6.62357C1.00841 7.53635 1.3194 8.70256 2.23 9.23226V9.23226C2.8219 9.57399 3.18653 10.2055 3.18653 10.889C3.18653 11.5725 2.8219 12.204 2.23 12.5458V12.5458C1.32056 13.0719 1.00923 14.2353 1.53435 15.1453V15.1453L2.16593 16.2346C2.41265 16.6797 2.8266 17.0082 3.31619 17.1474C3.80578 17.2865 4.33064 17.2249 4.77462 16.976V16.976C5.21108 16.7213 5.73119 16.6515 6.21934 16.7822C6.70749 16.9128 7.12324 17.233 7.37416 17.6716C7.5387 17.9488 7.62711 18.2646 7.63046 18.587V18.587C7.63046 19.6435 8.48696 20.5 9.5435 20.5H10.7975C11.8505 20.5 12.7055 19.6491 12.7105 18.5961V18.5961C12.7081 18.088 12.9089 17.6 13.2682 17.2407C13.6275 16.8814 14.1155 16.6806 14.6236 16.6831C14.9452 16.6917 15.2596 16.7797 15.5389 16.9394V16.9394C16.4517 17.4653 17.6179 17.1543 18.1476 16.2437V16.2437L18.8067 15.1453C19.0618 14.7075 19.1318 14.186 19.0012 13.6963C18.8706 13.2067 18.5502 12.7893 18.111 12.5366V12.5366C17.6718 12.2839 17.3514 11.8665 17.2208 11.3769C17.0902 10.8873 17.1603 10.3658 17.4154 9.92792C17.5812 9.63829 17.8214 9.39815 18.111 9.23226V9.23226C19.0161 8.70285 19.3264 7.54345 18.8067 6.63272V6.63272V6.62357Z"
        stroke="#1B1D21"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="10.1751"
        cy="10.889"
        r="2.63616"
        stroke="#1B1D21"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SettingsIcon;
